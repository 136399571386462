* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.flex {
  display: flex;
  height: 100vh;
}

.editor {
  width: 50%;
  padding: 10px;
  height: 100%;
}
